import "./SectionContactUs.css"
import BSoftTextInput from  "../smallComponents/bstyleInput"
import BSoftButton2 from  "../smallComponents/BSoftButton2"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import BSoftTextarea from  "../smallComponents/bstyleTextarea"
import { useState,useEffect } from "react";


function SectionContactUsDefaultFunct(){
    const [getSender,setSender] = useState(

        {
            senderSubjet:"",
            senderMessage:"",
            senderName:""
        }
    );

    const handleFormValueChange = (event) =>{
        setSender(previousState => {
            return {
              ...previousState, [event.target.id]: event.target.value    
            }
        });
    };


    const handleMailSending = (e) => {
        e.preventDefault();

        var email = "contact@bonkoungoutech.com";
        var subject = getSender.senderSubjet;
        var emailBody = getSender.senderMessage;
        document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
           
 
    };


    return(

        <>
                <section className="sectionContactUs">

                    <div className="sectionContactUsContainer sectionContainer">

                        <div className="row" >



                            <div className="fORMcONTACTuS col-md ">

                                <form>

                                    <div className="inputText inputTex">
                                        <BSoftTextInput  inputLabelStyle={{ backgroundColor: 'white', color:'black' }} type="text" label="Subject" id="senderSubjet" onChange={handleFormValueChange}/>
                                        
                                    </div>

                                    <div className="inputText inputTex">
                                        <BSoftTextarea inputLabelStyle={{ backgroundColor: 'white', color:'black' }} type="text" label="Message" id="senderMessage" onChange={handleFormValueChange}/>
                                    </div>


                                    <div className="buttonSubmitLoginInfo" id="senderMessage" onClick={handleMailSending}>
                                        <BSoftButton1 label="Go Now" style={{ 'margin-top':'20px', 'margin-bottom':'20px', backgroundColor: 'white', color:'#4accd5' }} />
                                    </div>

                                </form>

                            </div>     

                        </div>

                    </div>

                </section>

        
        </>


    );
}

export default SectionContactUsDefaultFunct;